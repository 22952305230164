import React, {Component} from 'react';
/* eslint-disable max-lines */
/* global dataLayer */
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import browserHistory from 'react-router/lib/browserHistory';
import {
  recentlyViewedData,
  RemoteDexieRecentlyViewedDataService,
} from '@spoonflower/recently-viewed-manager';
import {setAddition, setDesign, setProductSize, setQuantity, setSubstrate} from '../../actions/addToCart';
import {clearFlashMessage} from '../../actions/flashMessage';
import {modalClose, modalOpen, pageSetup, productPageReset} from '../../actions/pageSetup';
import ErrorLazy from '../../components/Error/ErrorLazy';
import browserHistoryWrapper from '../../components/Reusable/LinkWrapper/browserHistoryWrapper';
import {BUTTON_CONTENT_DEFAULT, CART_CONTENT_CHANGED, FABRIC, FABRIC_BLANK, HOME_GOOD, SOLID, WALLPAPER} from '../../constants/Codes';
import {FABRIC_PARAM, ORDER_ITEM_ID} from '../../constants/Parameters';
import {IS_WALLPAPER} from '../../constants/Products';
import {GUEST, REGISTERED, UNAUTHENTICATED} from '../../constants/userTypes';
import {fetchCartOrders} from '../../entities/cart/api/fetchCartOrders';
import {initWideLayout} from '../../entities/layout/initLayout';
import selectGardenHost from '../../entities/pageSetup/apiHosts/selectors/selectGardenHost';
import {selectCurrentPath} from '../../entities/pageSetup/path/selectCurrentPath';
import {selectIsProduction} from '../../environment/runtimeEnv/selectors';
import {MultiLocaleRouter} from '../../services';
import {isProductType, productTypeAbbreviatedShape} from '../../shapes/products';
import {getAddToCartButtonContent} from '../../utils/addToCartHelpers';
import CartOperations from '../../utils/cartOperations';
import {addToCartEvent} from '../../utils/dataLayer';
import ImageUrls, {MIDDLE_SIZE_THUMBNAIL} from '../../utils/design';
import {slideInModalOnClick} from '../../utils/slideInModalOnClick';
import {orderItemFromParams, quantityFromOrderItem, queryParamsOverrideUserPreferences} from '../../utils/stateToPropsHelpers';
import {upsertUrlQuery} from '../../utils/url';
import {isNotUndefined, isUndefined, objectIsEmpty} from '../../utils/validation';
import UserCollectionsContainer from '../Design/UserCollectionsContainer';
import fetchUserCollections from '../Design/api/fetchUserCollections';
import FabricContainer from './FabricContainerLazy';
import HomeGoodContainer from './HomeGoodContainerLazy';
import WallpaperContainer from './WallpaperContainerLazy';
import fetchAllFabricsData from './api/fetchAllFabricsData';
import fetchAllWallpapersData from './api/fetchAllWallpapersData';
import fetchColorways from './api/fetchColorways';
import fetchDesign from './api/fetchDesign';
import fetchHomeGoodPricing from './api/fetchHomeGoodPricing';
import fetchProductSlugTranslations from './api/fetchProductSlugTranslations';
import patchCurrentUser from './api/patchCurrentUser';
import initFabricPage from './pageSetup/fabricPageSetup';
import {initHomeGoodTitle} from './pageSetup/homeGoodPageSetup';
import initOrderItem from './pageSetup/orderItemSetup';
import {initSolidPageTitle} from './pageSetup/solidPageSetup';
import initWallpaperPage from './pageSetup/wallpaperPageSetup';

const recentlyViewedService = (url) => (process.env.REACT_APP_IS_SERVER ?
  recentlyViewedData :
  new RemoteDexieRecentlyViewedDataService(url, document.body));

const recentlyViewedServiceMemo = memoize(recentlyViewedService);

const getProductKey = (analytics, productSize) =>
  (analytics &&
    typeof analytics === 'object' &&
    productSize &&
    productSize in analytics &&
    (analytics)[productSize].sku) || undefined;

const getParentSku = (analytics) =>
  (analytics &&
    typeof analytics === 'object' &&
    'parent_sku' in analytics &&
    (analytics)['parent_sku']) ||
  undefined;

export class ProductSharedContainer extends Component {
  constructor(props) {
    super(props);
    this.componentIsMounted = false;
    this.openFabricPreferencesModal = this.openFabricPreferencesModal.bind(this);
    this.openWallpaperCalculatorModal = this.openWallpaperCalculatorModal.bind(this);
    this.state = {
      buttonContent: getAddToCartButtonContent(BUTTON_CONTENT_DEFAULT),
      buttonContentIsAdded: false,
      slideInModal: false,
      orderItemFromResponse: {},
      quantityInputUpdateComplete: true
    };
    this.recentlyViewedUrl = this.props.isProd ? 'https://www.spoonflower.com/en/recently-viewed' : 'https://staging.spoonflower.com/en/recently-viewed';
    this.recentlyViewed = recentlyViewedServiceMemo(
      `${this.recentlyViewedUrl}/remote-access`
    );
  }

  componentDidMount() {
    const {dispatchPageSetup, dispatchCloseModal, designIdParams, dispatchSetDesign, orderItem, dispatch, designId, userCollections} = this.props;

    this.componentIsMounted = true;

    dispatchPageSetup();
    dispatchCloseModal();

    if (!designId) {
      dispatch(fetchDesign());
      dispatchSetDesign(designIdParams);
    }

    if (orderItem) {
      // Allows navigation from cart to editing PDP to select orderitem details
      this.updateStateFromOrderItem({});
    }

    if (!userCollections.isFetching && objectIsEmpty(userCollections.collections)) {
      dispatch(fetchUserCollections());
    }

    this.setState({
      buttonContent: getAddToCartButtonContent(BUTTON_CONTENT_DEFAULT, orderItem.id)
    });
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
    this.props.dispatchProductPageReset();
  }

  componentDidUpdate(prevProps) {
    const {
      designIdParams, addToCartDesignId, dispatchSetDesign, dispatchProductPageReset, orderItem,
      userIsFetching, userPreferencesOverridden, dispatch, queryParams, userGroups, designSlug,
      currentPath, currentOrigin,
      analytics, addToCartIsFetching, productSize,
    } = this.props;

    this.updateStateFromOrderItem(prevProps.orderItem);

    if (designIdParams && designIdParams !== prevProps.designIdParams) {
      dispatchProductPageReset();
      dispatch(fetchDesign());
    }
    if (designIdParams && addToCartDesignId !== designIdParams) {
      dispatchSetDesign(designIdParams);
    }

    if (orderItem.id !== prevProps.orderItem.id && isNotUndefined(orderItem.id) && this.componentIsMounted) {
      this.setState({
        buttonContent: getAddToCartButtonContent(BUTTON_CONTENT_DEFAULT, orderItem.id)
      });
    }

    // update user preferences if valid query prefs are used
    // if (userPreferencesOverridden && !userIsFetching) {
    //   dispatch(patchCurrentUser());
    // }

    if (isNotUndefined(designSlug) && queryParams.fabric === 'wallpaper_woven' && (userGroups.length === 0 || !userGroups.includes(439))) {
      browserHistory.replace(upsertUrlQuery(currentPath, {
        [FABRIC_PARAM]: 'wallpaper_peel_and_stick'
      }, currentOrigin));
    }

    const productKey = getProductKey(analytics, productSize);
    const parentSku = getParentSku(analytics);
    const productType = this.props.productTypeAbbreviated;

    if (!addToCartIsFetching && addToCartDesignId && productKey) {
      this.recentlyViewed.visit(addToCartDesignId.toString(), productKey, {
        productSku: productKey,
        productSize,
        ...(parentSku ? {
          parentSku
        } : {}),
        ...(productType ? {
          productType
        } : {})
      });
    }
  }

  completingQuantityInputUpdate(isComplete) {
    this.setState({
      quantityInputUpdateComplete: isComplete
    });
  }

  changeAddToCartButtonContent() {
    const {orderItem} = this.props;

    this.setState({
      buttonContent: getAddToCartButtonContent(CART_CONTENT_CHANGED, orderItem.id),
      buttonContentIsAdded: true
    });
    setTimeout(() => this.componentIsMounted && this.setState({
      buttonContent: getAddToCartButtonContent(BUTTON_CONTENT_DEFAULT, orderItem.id),
      buttonContentIsAdded: false
    }), 4000);
  }

  addToCartClick(productTypeAbbreviated, addToCartRequestBody, overrides) {
    const {orderItem} = this.props;

    return !objectIsEmpty(orderItem) ?
      this.updateCartItem(overrides.chunkSize, addToCartRequestBody) :
      this.addToCart(productTypeAbbreviated, addToCartRequestBody, overrides);
  }

  applyRequestOverrides(productTypeAbbreviated, requestBody, overrides) {
    requestBody.quantity = overrides.quantity || requestBody.quantity;

    switch (productTypeAbbreviated) {
      case FABRIC:
      case WALLPAPER:
        requestBody.fabric_size = overrides.productSize || requestBody.fabric_size;
        break;
      case HOME_GOOD:
        requestBody.home_good_code = overrides.productSize || requestBody.home_good_code;
        break;
      default: break;
    }
  }

  addToCart(productTypeAbbreviated, requestBody, overrides) {
    const {userId, userType, country, measurementSystem, currency, locale, dispatchClearFlashMessage, stayLegacyUser} = this.props;

    this.applyRequestOverrides(productTypeAbbreviated, requestBody, overrides);
    this.componentIsMounted && this.changeAddToCartButtonContent();
    dispatchClearFlashMessage();

    return CartOperations.addToCart(productTypeAbbreviated, requestBody, userId, stayLegacyUser, country, measurementSystem, currency, locale, userType === GUEST);
  }

  updateCartItem(chunkSize, addToCartRequestBody) {
    const {
      dispatchClearFlashMessage, quantity, selectedSubstrate, productTypeAbbreviated, productSize, params, orderItem,
      userId, country, measurementSystem, currency, locale, currentPath, currentOrigin
    } = this.props;

    dispatchClearFlashMessage();

    return CartOperations
      .updateCartItem(
        productTypeAbbreviated, quantity, selectedSubstrate, productSize, chunkSize, params, orderItem, userId, country,
        measurementSystem, currency, locale, addToCartRequestBody
      )
      .then((newOrderItem) => {
        this.handleOrderItemResponse(newOrderItem, userId);
        // If cart item is edited on the PDP, and the edit operation results in a new order item ID, update the order
        // item ID in the location's query before pushing the user to the cart, so that the back button "works."
        browserHistory.replace(upsertUrlQuery(currentPath, {
          [ORDER_ITEM_ID]: newOrderItem.id
        }, currentOrigin));
      })
      .then(() => (
        browserHistoryWrapper.push(MultiLocaleRouter.localePathname('cart')))
      );
  }

  // Handle the response from a creation, updating, or replacement of an order item. Set the order item from the response
  // to the component state to differentiate between an order item being edited and an order item that simply needs temporary
  // rendering to show success to the user.
  handleOrderItemResponse(orderItem, userId, track = true) {
    this.setState({
      orderItemFromResponse: orderItem
    });
    // track add-to-cart-click with GTM
    if (track) {
      dataLayer.push(addToCartEvent(orderItem, userId));
    }
  }

  updateStateFromOrderItem(prevOrderItem) {
    const {
      productTypeAbbreviated, orderItem, productSize, selectedSubstrate, queryParams, dispatchSetQuantity,
      dispatchSetProductSize, dispatchSetSubstrate, dispatchSetAddition, addition
    } = this.props;

    if (orderItem.isFetching) {
      return;
    }

    if (!objectIsEmpty(orderItem) && (objectIsEmpty(prevOrderItem) || orderItem.quantity.value !== prevOrderItem.quantity.value)) {
      dispatchSetQuantity(orderItem.quantity.value);
    }

    if (isNotUndefined(orderItem.fabric)) {
      const orderItemProductSize = orderItem.fabric.size;
      const orderItemFabricCode = orderItem.fabric.code;
      const isNewOrderItemId = orderItem.id !== prevOrderItem.id;

      if (productTypeAbbreviated === HOME_GOOD) {
        const orderItemHomeGoodSize = get(orderItem, 'merchandise.home_good_item.home_good.code');
        const orderItemHomeGoodAddition = get(orderItem, 'merchandise.home_good_item.home_goods_additions[0].code');
        // Home Good Addition Logic: if we add more than one addition per homegood this will need to be updated

        if (isNotUndefined(orderItemHomeGoodSize) && productSize !== orderItemHomeGoodSize && isNewOrderItemId) {
          dispatchSetProductSize(orderItemHomeGoodSize);
        }
        if (isNotUndefined(orderItemHomeGoodAddition) && orderItemHomeGoodAddition !== addition && isNewOrderItemId) {
          dispatchSetAddition(orderItemHomeGoodAddition);
        }
      } else if (isNotUndefined(orderItemProductSize) && productSize !== orderItemProductSize && isNewOrderItemId) {
        dispatchSetProductSize(orderItemProductSize);
      }

      if (isNotUndefined(orderItemFabricCode) && selectedSubstrate !== orderItemFabricCode && isUndefined(queryParams.fabric)) {
        dispatchSetSubstrate(orderItemFabricCode);
      }
    }
  }

  openFabricPreferencesModal(event) {
    event.preventDefault();
    this.props.dispatchOpenModal('fabric-shop');
    slideInModalOnClick(this);
  }

  openWallpaperCalculatorModal(event) {
    event.preventDefault();
    this.props.dispatchOpenModal('wallpaper-calculator');
    slideInModalOnClick(this);
  }

  render() {
    const {
      route, params, country, measurementSystem, currency, userPreferencesOverridden, userId, userType, viewport, designId,
      designName, userIsPrivateBuyer, designFileName, designerUserName, designerId, quantity, addToCartIsFetching, reviewSummary,
      dispatchSetProductSize, dispatchSetSubstrate, dispatchSetQuantity, taxRate, userIsFetching, queryParams,
      userAuthenticated, orderItem, productTypeAbbreviated, designThumbnailPartial, productAvailable, gardenHost, dispatchSetAddition, colorways, designSlug,
      designFailed, fabricsFailed, wallpapersFailed, homeGoodFailed
    } = this.props;

    const latestOrderItem = !objectIsEmpty(orderItem) ? orderItem : this.state.orderItemFromResponse;
    const orderItemIsFetching = !objectIsEmpty(latestOrderItem) && !!latestOrderItem.isFetching;
    const designThumbnailSimple = ImageUrls.cipheredDesignThumbnail(gardenHost, designId, designThumbnailPartial, MIDDLE_SIZE_THUMBNAIL, FABRIC);
    const pdpTypeClassname = productTypeAbbreviated && `x-${productTypeAbbreviated.replace('_', '-').toLowerCase()}`;
    const swatchPath = IS_WALLPAPER(productTypeAbbreviated) ? WALLPAPER : FABRIC;
    const swiperParams = {
      loop: true
    };

    const selectedColorway = {
      id: designId,
      title: designName,
      slug: designSlug,
      thumbnail: designThumbnailSimple
    };

    const productContainerProps = {
      params, country, measurementSystem, currency, userId, userType, viewport, designId, designName, designThumbnailSimple, userIsPrivateBuyer,
      designFileName, taxRate, designerUserName, designerId, quantity, addToCartIsFetching, userPreferencesOverridden, reviewSummary, colorways,
      buttonContent: this.state.buttonContent, buttonContentIsAdded: this.state.buttonContentIsAdded, userIsFetching,
      slideInModal: this.state.slideInModal, openFabricPreferencesModal: this.openFabricPreferencesModal,
      openWallpaperCalculatorModal: this.openWallpaperCalculatorModal, userAuthenticated, dispatchSetAddition,
      orderItemIsFetching, orderItem, productTypeAbbreviated, queryParams, productAvailable, selectedColorway,
      productExtensionClass: pdpTypeClassname, swiperParams, designSlug,
      completingQuantityInputUpdate: (isComplete) => this.completingQuantityInputUpdate(isComplete),
      quantityInputUpdateComplete: this.state.quantityInputUpdateComplete,
      setProductSize: (productSize) => dispatchSetProductSize(productSize), setSubstrate: (substrate) => dispatchSetSubstrate(substrate),
      setQuantity: (quantity) => dispatchSetQuantity(quantity),
      addToCartClick: (addToCartRequestBody, overrides) => this.addToCartClick(productTypeAbbreviated, addToCartRequestBody, overrides),
      addSwatchToCartClick: (addToCartRequestBody, overrides) => this.addToCartClick(swatchPath, addToCartRequestBody, overrides),
    };

    let SubContainer;

    const requestFailed = designFailed || fabricsFailed || wallpapersFailed || homeGoodFailed;

    switch (productTypeAbbreviated) {
      case FABRIC:
        SubContainer = FabricContainer;
        productContainerProps.fabricType = productTypeAbbreviated;
        break;
      case WALLPAPER:
        SubContainer = WallpaperContainer;
        productContainerProps.wallpaperType = productTypeAbbreviated;
        break;
      case HOME_GOOD:
        SubContainer = HomeGoodContainer;
        productContainerProps.homeGoodType = route.productType;
        break;
      default:
        return <ErrorLazy/>;
    }

    if (requestFailed) {
      return <ErrorLazy/>;
    }

    return (
      <section itemScope itemType='https://schema.org/Product' className={pdpTypeClassname}>
        {<SubContainer {...productContainerProps} />}
      </section>
    );
  }
}

ProductSharedContainer.propTypes = {
  currentPath: PropTypes.string,
  currentOrigin: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  dispatchPageSetup: PropTypes.func.isRequired,
  dispatchClearFlashMessage: PropTypes.func.isRequired,
  dispatchCartOrders: PropTypes.func.isRequired,
  dispatchSetDesign: PropTypes.func.isRequired,
  dispatchSetProductSize: PropTypes.func.isRequired,
  dispatchSetSubstrate: PropTypes.func.isRequired,
  dispatchSetQuantity: PropTypes.func.isRequired,
  dispatchProductPageReset: PropTypes.func.isRequired,
  dispatchOpenModal: PropTypes.func.isRequired,
  dispatchCloseModal: PropTypes.func.isRequired,
  dispatchSetAddition: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  taxRate: PropTypes.number,
  route: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  userPreferencesOverridden: PropTypes.bool.isRequired,
  userIsFetching: PropTypes.bool.isRequired,
  userCollections: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    collections: PropTypes.arrayOf(PropTypes.shape({
      design_ids: PropTypes.arrayOf(PropTypes.number),
      designer_only: PropTypes.bool,
      id: PropTypes.number,
      last_design_added_at: PropTypes.string,
      name: PropTypes.string,
      show_collection: PropTypes.bool,
      slug: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.number,
        screen_name: PropTypes.string,
      }),
    }))
  }),
  locale: PropTypes.string.isRequired,
  userId: PropTypes.number,
  userType: PropTypes.oneOf([UNAUTHENTICATED, GUEST, REGISTERED]),
  viewport: PropTypes.string,
  currency: PropTypes.string.isRequired,
  designId: PropTypes.number,
  designIdParams: PropTypes.number,
  designName: PropTypes.string,
  designThumbnailPartial: PropTypes.string,
  selectedSubstrate: PropTypes.string,
  productSize: PropTypes.string,
  userIsPrivateBuyer: PropTypes.bool,
  userAuthenticated: PropTypes.bool,
  designFileName: PropTypes.string,
  designerUserName: PropTypes.string,
  productTypeAbbreviated: productTypeAbbreviatedShape.isRequired,
  designerId: PropTypes.number,
  pendingOrderId: PropTypes.number,
  savedOrderId: PropTypes.number,
  addToCartDesignId: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  addToCartIsFetching: PropTypes.bool.isRequired,
  orderItem: PropTypes.object.isRequired,
  addition: PropTypes.string,
  productAvailable: PropTypes.bool.isRequired,
  colorways: PropTypes.shape({
    isFetching: PropTypes.bool,
    colorways: PropTypes.array
  }),
  featureFlags: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
  queryParams: PropTypes.shape({
    fabric: PropTypes.string,
    size: PropTypes.string,
    addition: PropTypes.string,
    currency: PropTypes.string,
    country: PropTypes.string,
    measurementSystem: PropTypes.string,
    color: PropTypes.string
  }),
  reviewSummary: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  gardenHost: PropTypes.string.isRequired,
  designSlug: PropTypes.string,
  userGroups: PropTypes.array,
  solidColor: PropTypes.string,
  stayLegacyUser: PropTypes.bool,
  designFailed: PropTypes.bool,
  fabricsFailed: PropTypes.bool,
  wallpapersFailed: PropTypes.bool,
  homeGoodFailed: PropTypes.bool,
  analytics: PropTypes.object,
  isProd: PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => {
  const productType = ownProps.route.productType;
  let productTypeAbbreviated = '';

  if ([FABRIC, WALLPAPER, FABRIC_BLANK, SOLID].includes(productType) && isProductType(productType)) {
    productTypeAbbreviated = productType;
  } else if (productType.startsWith(HOME_GOOD)) {
    productTypeAbbreviated = HOME_GOOD;
  } else {
    throw new Error(`Invalid productType: ${productType}`);
  }

  const props = {
    ...queryParamsOverrideUserPreferences(state), // country, measurementSystem, currency, userPreferencesOverridden
    userAuthenticated: !!state.user.id,
    userIsFetching: state.user.isFetching,
    locale: ownProps.params.pageLang,
    userId: state.user.id,
    userType: state.user.userType,
    userGroups: state.user.user_groups,
    viewport: state.pageSetup.viewport,
    productTypeAbbreviated,
    taxRate: state.addToCart.tax_rate,
    queryParams: state.routing.locationBeforeTransitions.query,
    designId: state.design.id,
    designIdParams: parseInt(ownProps.params.designId, 10),
    designName: state.design.name,
    designThumbnailPartial: state.design.design_thumbnail,
    selectedSubstrate: state.addToCart.selectedSubstrate,
    designFileName: state.design.filename,
    userIsPrivateBuyer: state.design.user_is_private_buyer,
    designerUserName: state.design.designer.screen_name,
    designerId: state.design.designer.id,
    addToCartDesignId: state.addToCart.designId,
    quantity: state.addToCart.quantity || quantityFromOrderItem(state) || 1,
    addToCartIsFetching: state.addToCart.isFetching,
    reviewSummary: state.reviews.summary,
    orderItem: orderItemFromParams(state) ?? {},
    productSize: state.addToCart.productSize,
    addition: state.addToCart.addition,
    productAvailable: state.addToCart.availability,
    gardenHost: selectGardenHost(state),
    currentPath: selectCurrentPath(state),
    currentOrigin: state.pageSetup.origin,
    userCollections: state.userCollections,
    solidColor: state.solids.color,
    colorways: state.colorways,
    featureFlags: state.featureFlags,
    stayLegacyUser: state.user.stayLegacyUser,
    designFailed: state.design.error,
    fabricsFailed: state.fabrics.failed,
    wallpapersFailed: state.wallpapers.failed,
    homeGoodFailed: state.addToCart.failed,
    analytics: state.addToCart.analytics,
    isProd: selectIsProduction(state),
  };

  if (isUndefined(state.design.slug)) {
    return props;
  }

  return Object.assign(props, {
    designSlug: state.design.slug
  });
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  dispatchPageSetup() {
    dispatch(pageSetup({
      contentInnerExtensions: 'x-1294'
    }));
  },
  dispatchClearFlashMessage() {
    dispatch(clearFlashMessage());
  },
  dispatchCartOrders() {
    return dispatch(fetchCartOrders());
  },
  dispatchSetDesign(designId) {
    dispatch(setDesign(designId));
  },
  dispatchSetProductSize(size) {
    dispatch(setProductSize(size));
  },
  dispatchSetAddition(addition) {
    dispatch(setAddition(addition));
  },
  dispatchSetSubstrate(fabricString) {
    dispatch(setSubstrate(fabricString));
  },
  dispatchSetQuantity(quantity) {
    dispatch(setQuantity(quantity));
  },
  dispatchProductPageReset() {
    dispatch(productPageReset());
  },
  dispatchOpenModal(name) {
    dispatch(modalOpen({
      modal: {
        displayModal: true,
        modalName: name
      }
    }));
  },
  dispatchCloseModal() {
    dispatch(modalClose({
      modal: {
        displayModal: false,
        modalName: ''
      }
    }));
  },
});


const ProductSharedContainerConnected = connect(mapStateToProps, mapDispatchToProps)(ProductSharedContainer);

ProductSharedContainerConnected.fetchDataOnServer = new Set([
  fetchAllFabricsData,
  fetchAllWallpapersData,
  fetchHomeGoodPricing,
  fetchDesign,
  fetchColorways,
  ...UserCollectionsContainer.fetchDataOnServer
]);
ProductSharedContainerConnected.extendInitialStateBeforeDataLoad = new Set([
  initHomeGoodTitle,
  initSolidPageTitle,
  initWideLayout
]);

ProductSharedContainerConnected.extendInitialStateAfterDataLoad = new Set([
  initFabricPage,
  initWallpaperPage,
  initOrderItem
]);

ProductSharedContainerConnected.extendRedirectsConfig = new Set([fetchProductSlugTranslations]);
ProductSharedContainerConnected.pageKey = 'ProductSharedContainerPDP';

export default ProductSharedContainerConnected;
